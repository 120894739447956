import React, { FC } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa/cssVars';
import { st, classes } from './App.st.css';
import settingsParams from '../../settingsParams';
import { DataHooks } from '../../../../consts/dataHooks';

const App: FC = () => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  return (
    <div
      className={st(classes.root, {})}
      data-hook={DataHooks.MyBookingsMainWidget}
    >
      <div className={classes.header}>
        <h2 data-hook="app-title">
          {t('app.widget.welcome')} {settings.get(settingsParams.greetingsText)}
        </h2>
      </div>

      {experiments.enabled('specs.test.ShouldHideButton') ? null : (
        <Button className={classes.mainButton}>Click me</Button>
      )}
    </div>
  );
};

export default App;
